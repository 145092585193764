import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import { HTMLContent } from "../components/Content";

export const TextBannerPageTemplate = ({
  title,
  banner,
  bannerVerticalOffset = 0,
  content,
  contentComponent,
  location,
}) => {
  const PageContent = contentComponent || Content;

  useEffect(() => {
    // gatsby doesn't handle anchors when they are used in Link, this covers it for How it Works page
    setTimeout(() => {
      if (typeof window !== "undefined" && location.hash !== "") {
        const anchors = [...document.querySelectorAll("h3")].filter(
          elem => elem.innerHTML === location.hash.substr(1),
        );
        if (anchors.length !== 0) {
          window.scrollTo(0, anchors[0].offsetTop);
        }
      }
    }, 100);
  }, [location.hash]);

  return (
    <div className="content">
      <div
        className="full-width-image-container img-top-centered m-t-none m-b-none"
        style={{
          backgroundImage: `url(${
            banner.childImageSharp ? banner.childImageSharp.fluid.src : banner
          })`,
          justifyContent: "flex-end",
          alignItems: "flex-end",
          flexDirection: "row",
          backgroundPosition: `50% ${bannerVerticalOffset}%`,
        }}
      >
        <span
          className="container"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
            flexGrow: "1",
          }}
        >
          <h2
            className="has-text-weight-medium is-size-1 has-background-primary-half-opaque m-b-xxl"
            style={{
              color: "white",
              padding: "1rem",
            }}
          >
            {title}
          </h2>
          {/* <h3
            className="has-text-weight-medium is-size-2 has-background-primary-half-opaque m-t-none"
            style={{
              color: "white",
              padding: "1rem",
              marginBottom: "3.5rem ",
            }}
          >
            {subTitle}
          </h3> */}
        </span>
      </div>
      <section className="section section--gradient">
        <div className="container">
          <div className="columns">
            <div className="column is-10 is-offset-1">
              <PageContent
                className="content has-text-justified"
                content={content}
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

TextBannerPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  banner: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  bannerVerticalOffset: PropTypes.number,
  content: PropTypes.string,
  location: PropTypes.object,
  contentComponent: PropTypes.func,
};

const TextBannerPage = ({ data, location }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <TextBannerPageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        banner={post.frontmatter.banner}
        bannerVerticalOffset={post.frontmatter.bannerVerticalOffset}
        content={post.html}
        location={location}
      />
    </Layout>
  );
};

TextBannerPage.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object,
};

export default TextBannerPage;

export const textBannerPageQuery = graphql`
  query TextBannerPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        banner {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        bannerVerticalOffset
      }
    }
  }
`;
